<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="white"
      shaped
    >
      <v-list-item
        color="primary"
        to="/"
      >
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item color="primary">
        <v-list-item-content>
          <v-list-item-title> Products </v-list-item-title>

          <v-list>
            <v-list-item to="/calendar">
              <v-list-item-title>Calendar</v-list-item-title>
            </v-list-item>
            <v-list-item to="/designs">
              <v-list-item-title>Design Work</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="name in items"
        :key="name"
        :to="{ name }"
        :exact="name === 'Home'"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="name" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>
